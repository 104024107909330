import './PromotionFormModal.css';
import { Promotion } from '../../../../../interfaces/Menu/Promotion';
import { IoClose } from 'react-icons/io5';
import { useState } from 'react';
import { MenuService } from '../../../../../services/Menu/MenuService';
import { ToggleSwitch } from '../../Components/ToggleSwitch';
import Swal from 'sweetalert2';

interface PromotionFormModalProps {
    closeModal: () => void;
    promotion: Promotion;
    isNew: boolean;
    updatePromotions: (updatedPromotion: Promotion) => void;
}

export function PromotionFormModal({ closeModal, promotion, isNew, updatePromotions }: PromotionFormModalProps) {
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [isPublished, setIsPublished] = useState<boolean>(promotion.status);
    const [promotionId, setPromotionId] = useState(!isNew ? promotion.id : "");
    const [image, setImage] = useState<string>(promotion.image ?? "");
    const [promotionForm, setPromotionForm] = useState<Promotion>({
        id: promotion.id,
        name: promotion.name,
        description: promotion.description,
        status: promotion.status
    });

    const handleSavePromotion = async (e: React.FormEvent) => {
        e.preventDefault();
        if (isNew && promotionId === "") {
            const resp = await MenuService.addPromotion(promotionForm);
            if (resp.success) {
                Swal.fire({
                    title: resp.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 3000
                });
                updatePromotions(resp.data); 
                setPromotionForm({ ...promotionForm, id: resp.data.id });
                setPromotionId(resp.data.id);
            }
        } else {
            const resp = await MenuService.updatePromotion(promotionForm);
            if (resp.success) {
                Swal.fire({
                    title: resp.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 3000
                });
                updatePromotions(resp.data); 
                setPromotionForm({ ...promotionForm, id: resp.data.id });
                setPromotionId(resp.data.id);
            }
        }
    };

    const handleTogglePublished = () => {
        setPromotionForm({ ...promotionForm, status: !isPublished });
        setIsPublished(!isPublished);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPromotionForm({ ...promotionForm, [e.target.name]: e.target.value });
    };

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            if (promotionId) {
                const formData = new FormData();
                formData.append('image', file);
                formData.append('promotion_id', promotionId);
                const resp = await MenuService.addPromotionImage(formData);
                if (resp.success) {
                    Swal.fire({
                        title: resp.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 3000
                    });
                    setImage(URL.createObjectURL(file));
                } else {
                    Swal.fire({
                        title: 'Error al subir la imagen',
                        icon: 'error',
                        showConfirmButton: true,
                    });
                }
            }
        }
    };

    return (
        <div className="promotion-form-modal">
            <div className="promotion-form-modal-content">
                <button className="close-btn" onClick={closeModal}><IoClose /></button>
                <h3>{isNew ? "Agregar Promoción" : "Editar Promoción"}</h3>
                <form onSubmit={handleSavePromotion}>
                    <label htmlFor="promotion-name">Título</label>
                    <input
                        id="promotion-name"
                        type="text"
                        value={promotionForm.name}
                        placeholder="Título de la promoción"
                        name='name'
                        required
                        onChange={handleChange}
                    />
                    <label htmlFor="promotion-description">Descripción</label>
                    <textarea
                        id="promotion-description"
                        rows={4}
                        value={promotionForm.description}
                        name='description'
                        placeholder="Descripción de la promoción"
                        onChange={handleChange}
                        maxLength={800}
                    />
                    <ToggleSwitch
                        label="Activo"
                        checked={isPublished}
                        onChange={handleTogglePublished}
                    />
                    </form>
                    {promotionId &&
                        <div className="image-promotion">
                            {image ? <img src={image} alt="Promotion" /> : <div className='empty-image'>Agrega una imagen...</div>}
                            <button className="upload-btn">
                                <label htmlFor="image-upload" style={{ cursor: 'pointer' }}>Subir imagen</label>
                                <input
                                    type="file"
                                    id="image-upload"
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                    accept="image/*"
                                />
                            </button>
                        </div>
                    }
                    <button className='submit-btn' type="submit" onSubmit={handleSavePromotion}>Guardar</button>
               
            </div>
        </div>
    );
}
