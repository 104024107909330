import React from "react";
import SubscriptionCard from "../components/SubscriptionCard";
import './Subscription.css';
import { StandardCard } from "../../dashboard/Subscriptions/Components/StandardCard/StandardCard";

const Subscription: React.FC = () => {
    return (
        <div className="subscription-container">
            <div className="subscription-content-wrapper">
                <h2 className="title-left">TODO ESTA LISTO.</h2>
                <StandardCard 
                    isVisible={false} 
                    isLanding={true} 
                />
            </div>
        </div>
    );
};

export default Subscription;
