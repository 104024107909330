import logo from '../../../assets/img/logo.png'
import './Header.css'
import { ProfileMenu } from './ProfileMenu/ProfileMenu';
import { useNavigate } from 'react-router-dom';

export function Header(){
    const navigate = useNavigate();
    const navigateToHome = () => {
        navigate('/');
    };

    return (
        <div className='header'>
            <button onClick={navigateToHome}><img src={logo} alt="" className='logo'/></button>
            <ProfileMenu/>
        </div>   
    );
}