import './ImageUploadModal.css';
import React, { useState } from 'react';
import { IoClose } from "react-icons/io5";
import { FileUploader } from "react-drag-drop-files";
import Swal from "sweetalert2";
import { BranchService } from '../../../../../services/VentureService/BranchService'; // Asegúrate de importar el servicio
import { BranchImage } from '../../../../../interfaces/Venture/BranchImage';

interface ImageUploadModalProps {
  closeModal: () => void;
  images: BranchImage[];
  updateBranchImages: (images: BranchImage[]) => void;
  branchId: string | undefined;
}

const fileTypes = ["JPG", "PNG", "GIF", "JPEG", "SVG"];

export function ImageUploadModal({ closeModal, images, updateBranchImages, branchId }: ImageUploadModalProps) {
  
  
  const [isUploading, setIsUploading] = useState<boolean>(false);

  

  const handleChange = async (files: FileList) => {
    if (!branchId) {
      console.error('Branch ID is missing.');
      return;
    }

    setIsUploading(true);

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('images', files[i]);
    }

    formData.append('branch_id', branchId);

    try {
      const resp = await BranchService.addBranchImages(formData);
      if(resp.success){
        const imagesList = resp.data.map((image: BranchImage) => ({
          id: image.id,
          photo: image.photo,
        }));
        updateBranchImages(imagesList)
      }
      
    } catch (error) {
      console.error('Upload failed:', error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleDeletePhoto = (e: React.MouseEvent<HTMLButtonElement>, id: string) => {
    e.preventDefault();

    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#000',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Llama al servicio para eliminar la imagen
          await BranchService.deleteBranchImage(id);
          
          // Actualiza la lista de imágenes en el estado después de eliminar
          const updatedImages = images.filter(image => image.id !== id);
          updateBranchImages(updatedImages);
  
          // Muestra un mensaje de éxito
          Swal.fire({
            title:'¡Eliminado!',
            text:'La fotografía ha sido eliminada.',
            icon:'success',
            confirmButtonColor: '#000',
          }
          );
        } catch (error) {
          console.error('Error eliminando la imagen:', error);
          Swal.fire({
            title:'Error',
            text:'Hubo un problema al eliminar la imagen.',
            icon:'error',
            confirmButtonColor: '#000',
          });
        }
      }
    });
  };

  return (
    <div className='upload-modal'>
      <div className='upload-modal-content'>
        <div className='btn-close-upload'>
          <button onClick={closeModal}><IoClose /></button>
        </div>
        <h2>Actualizar Geleria</h2>
        <div className='drag-and-drop'>
          <FileUploader 
            handleChange={handleChange} 
            name="file" 
            types={fileTypes} 
            multiple={true} 
            classes="dnd-input"
            label="Arrastra y suelta tus imágenes aquí o selecciónalas"
            disabled={isUploading} 
          />
        </div>
        <div className='my-images image-branch-group'>
          <br/>
            <h3>Mis imagenes</h3>
            {images.length > 0 
              ? 
                images.map((image, index) => (
                  <div key={index} className='gallery-image image-edit-content'>
                    <button className='delete-photo' onClick={(e) => handleDeletePhoto(e, image.id)}>Eliminar</button>
                    <img src={image.photo} alt={`Gallery image ${image.id}`} />
                  </div>
                ))
              :
              <div>Aún no cuentas con galería de fotos para esta sucursal</div>
            }
          
        </div>
      </div>
    </div>
  );
}