import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../views/store/context/AuthContext";
import { PrivateRouter } from "./PrivateRouter";
import { DashboardRouter } from "../views/dashboard/DashboardRouter";
import { Auth } from "../views/auth/Auth";
import { SendMailConfirmation } from "../views/auth/sendMailConfirmation/SendMailConfirmation";
import { SuccessConfirmation } from "../views/auth/successConfirmation/SuccessConfirmation";
import { ExpiredConfirmation } from "../views/auth/expiredConfirmation/ExpiredConfirmation";
import Landing from "../views/landing/Landing";

interface Context{
    dispatchUser?:any,
    user?:User
}

interface User{
    loggedIn:boolean
}

export function AppRouter(){
    const { user }:Context = useContext(AuthContext)
    //alert( user?.loggedIn )
    return (
        <Router>
            <Routes>

                <Route path="/" element={<Landing />} />

                <Route path="/auth" element={<Auth />} />
                <Route path="/confirmation" element={<SendMailConfirmation />} />
                <Route path="/success-confirmation/:data" element={<SuccessConfirmation />} />
                <Route path="/confirmation-expired" element={<ExpiredConfirmation />} />

                {/* Envolvemos la ruta protegida en PrivateRouter */}
                <Route
                    path="dashboard/*"
                    element={
                        <PrivateRouter loggedIn={user?.loggedIn}>
                            <DashboardRouter />
                        </PrivateRouter>
                    }
                />
                <Route
                    path="/"
                    element={
                        <PrivateRouter loggedIn={user?.loggedIn}>
                            <DashboardRouter />
                        </PrivateRouter>
                    }
                />

                {/* Redirigir a la ruta protegida si el usuario está logueado, de lo contrario, a la autenticación */}
                <Route path="*" element={<Navigate to={user?.loggedIn ? "/dashboard/home" : "/auth"} />} />
            </Routes>
        </Router>
    );
}
