import React, { useState } from 'react';
import { Login } from './login/Login';
import { Register } from './register/Register';
import logo from '../../assets/img/logo.png'
import './Auth.css';

export function Auth() {
    const [isLoginActive, setIsLoginActive] = useState(false);

    const handleToggle = () => {
        setIsLoginActive(!isLoginActive);
    };

    return (
        <div className="form-structor">
                <img src={logo} alt="logo" className='logo-login'/>
                <div className={`signup ${isLoginActive ? 'slide-up' : ''}`}>
                    <Register onToggle={handleToggle} />
                </div>
                <div className={`login ${isLoginActive ? '' : 'slide-up'}`}>
                    <Login onToggle={handleToggle} />
                </div>
        </div>
    );
}

