import './SendMailConfirmation.css'
import logo from '../../../assets/img/logo.png'
import { BsFillSendCheckFill } from "react-icons/bs";
import { AuthService } from '../../../services/auth/AuthService';
import Swal from 'sweetalert2';

export function SendMailConfirmation() {
    const handleClick = async (e: React.FormEvent) => {
        e.preventDefault();
        let emailSession: any = localStorage.getItem('email');
        let email: any = JSON.parse(emailSession);
        
        const resp = await AuthService.resendMail(email);
        if(resp.success){
            
            Swal.fire({
                title: resp.message,
                icon: 'success',
                showConfirmButton: false,
                timer: 3000
            });
        }
    }
    return (
        <div className="send-mail">
                <div className='msg-container'>
                    <img src={logo} alt="" />

                    <div className="subtitle">Ahora solo verifica tu cuenta para comenzar...</div>

                    <div className="description">
                        Hemos enviado un correo electrónico para verificar tu cuenta.
                        <br />
                        Si no encuentras el mensaje en tu bandeja principal, revisa las bandejas de spam y promociones.
                    </div>

                    <div className="ico-send">
                        <BsFillSendCheckFill className='icon'/>
                    </div>

                    <div className="resend">
                        ¿No recibiste el correo de verificación? 
                        <br />
                        <button onClick={handleClick}>Reenviar correo</button>
                    </div>
                    
                </div>
        </div>
    );
}

