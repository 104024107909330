import { MenuDesktop } from '../Components/MenuDesktop/MenuDesktop';
import { MenuMobile } from '../Components/MenuMobile/MenuMobile';
import './ProfileMenu.css';


export function ProfileMenu() {
   
    return (
        <div className='container__menu'>
            <div className='mobile__menu'>
                <MenuMobile/>
            </div>
            <div className='desktop__menu'>
                <MenuDesktop/>
            </div>
            
        </div>
    );
}
