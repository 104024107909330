import { ApiResponse } from "../../interfaces/response/ApiResponse";
import { Branch } from "../../interfaces/Venture/Branch";
import { AppService } from "../AppService";

export class BranchService{

    //POST

    public static async newBranch(obj:Branch):Promise<ApiResponse>{
        return (await AppService.post('/venture/new-branch', obj)).data;
    }

    public static async updateBranch(obj:Branch):Promise<ApiResponse>{
        return (await AppService.post('/venture/put-branch', obj)).data;
    }

    public static async addBranchImages(formData: FormData):Promise<ApiResponse>{
        return (await AppService.post('/venture/add-images-branch', formData)).data;
    }

    //GET

    public static async getBranch(obj:number):Promise<ApiResponse>{
        return (await AppService.get('/venture/get-branch/' + obj)).data;
    }

    public static async getBranchImages(obj:number):Promise<ApiResponse>{
        return (await AppService.get('/venture/get-images-branch/' + obj)).data;
    }

    //DELETE

    public static async deleteBranchImage(obj:string):Promise<ApiResponse>{
        return (await AppService.delete('/venture/delete-branch-image/'+ obj)).data;
    }

    public static async deleteBranch(obj:number):Promise<ApiResponse>{
        return (await AppService.delete('/venture/delete-branch/'+ obj)).data;
    }

}