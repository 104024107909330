import { ApiResponse } from "../../interfaces/response/ApiResponse";
import { Venture } from "../../interfaces/Venture/Venture";
import { AppService } from "../AppService";

export class VentureService{

    public static async setVenture(obj:Venture):Promise<ApiResponse>{
        return (await AppService.post('/venture/set-venture', obj)).data;
    }

    public static async setVentureImage(formData: FormData): Promise<ApiResponse> {
        return (await AppService.post('/venture/set-image-venture', formData)).data;
    }

    public static async getVentureInformation():Promise<ApiResponse>{
        return (await AppService.get('/venture/get-venture-information/')).data;
    }

}