import { ApiResponse } from "../../interfaces/response/ApiResponse";
import { Category } from "../../interfaces/Menu/Category";
import { Product } from "../../interfaces/Menu/Product";
import { Promotion } from "../../interfaces/Menu/Promotion";
import { AppService } from "../AppService";

export class MenuService{

    //POST
    /** Categories */
    public static async addCategory(obj:Category):Promise<ApiResponse>{
        return (await AppService.post('/menu/add-category', obj)).data;
    }

    public static async updateCategory(obj:Category):Promise<ApiResponse>{
        return (await AppService.post('/menu/put-category', obj)).data;
    }

    public static async addCategoryImage(formData: FormData):Promise<ApiResponse>{
        return (await AppService.post('/menu/upload-image-category', formData)).data;
    }

    /** Products */

    public static async addProduct(obj:Product):Promise<ApiResponse>{
        return (await AppService.post('/menu/add-product', obj)).data;
    }

    public static async updateProduct(obj:Product):Promise<ApiResponse>{
        return (await AppService.post('/menu/put-product', obj)).data;
    }

    public static async addProductImage(formData: FormData):Promise<ApiResponse>{
        return (await AppService.post('/menu/upload-image-product', formData)).data;
    }

    /** Promotions */

    public static async addPromotion(obj:Promotion):Promise<ApiResponse>{
        return (await AppService.post('/menu/add-promotion', obj)).data;
    }

    public static async updatePromotion(obj:Promotion):Promise<ApiResponse>{
        return (await AppService.post('/menu/put-promotion', obj)).data;
    }

    public static async addPromotionImage(formData: FormData):Promise<ApiResponse>{
        return (await AppService.post('/menu/upload-image-promotion', formData)).data;
    }

    //GET

    public static async getCategories():Promise<ApiResponse>{
        return (await AppService.get('/menu/get-categories')).data;
    }

    public static async getProducts():Promise<ApiResponse>{
        return (await AppService.get('/menu/get-products')).data;
    }

    public static async getPromotions():Promise<ApiResponse>{
        return (await AppService.get('/menu/get-promotions')).data;
    }

    //DELETE

    public static async deleteCategory(obj:string):Promise<ApiResponse>{
        return (await AppService.delete('menu/delete-category/'+ obj)).data;
    }

    public static async deleteProduct(obj:string):Promise<ApiResponse>{
        return (await AppService.delete('/menu/delete-product/'+ obj)).data;
    }

    public static async deletePromotion(obj:string):Promise<ApiResponse>{
        return (await AppService.delete('/menu/delete-promotion/'+ obj)).data;
    }

}