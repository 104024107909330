import { 
    TbCircleNumber1Filled, 
    TbCircleNumber2Filled, 
    TbCircleNumber3Filled, 
    TbCircleNumber4Filled,
    TbCircleNumber5Filled
} from "react-icons/tb";
import "./NewUserTour.css"

interface NewUserTourProps{
    handleNextStep: () => void;
    step: number;
}

export function NewUserTour({handleNextStep, step}:NewUserTourProps){
    
    return (
        <div className="new-user-tour">
            <div className="tour-content">
                {step == 1 &&
                    <div className="step-one">
                        <div className="number-step"><TbCircleNumber1Filled /></div>
                        <div className="text">
                            Para comenzar, es necesario que agregues la información de tu negocio, así como de sus sucursales, donde podrás incluir horarios, redes sociales, ubicación, etc.
                            <div className="button-content"><button className="next-step" onClick={handleNextStep}>De acuerdo</button></div>
                        </div>
                    </div>
                }

                {step == 2 &&
                    <div className="step-two">
                        <div className="number-step"><TbCircleNumber2Filled/></div>
                        <div className="text">
                            Luego, para poder comenzar a agregar productos, primero necesitarás crear categorías (refrescos, postres, cortes, etc.).
                            <div className="button-content"><button className="next-step" onClick={handleNextStep}>De acuerdo</button></div>
                        </div>
                    </div>
                }

                {step == 3 &&
                    <div className="step-three">
                        <div className="number-step"><TbCircleNumber3Filled/></div>
                        <div className="text">
                        Una vez que hayas creado tus categorías, ¡solo agrega los productos que ofreces!
                            <div className="button-content"><button className="next-step" onClick={handleNextStep}>De acuerdo</button></div>
                        </div>
                    </div>
                }    

                {step == 4 &&
                    <div className="step-four">
                        <div className="number-step"><TbCircleNumber4Filled /></div>
                        <div className="text">
                            Puedes agregar promociones o algún tipo de banner que consideres relevante para tu negocio (martes 2x1, nuevo producto, nueva sucursal, etc.).
                            <div className="button-content"><button className="next-step" onClick={handleNextStep}>De acuerdo</button></div>
                        </div>
                    </div>
                }    

                {step == 5 &&
                    <div className="step-five">
                        <div className="number-step"><TbCircleNumber5Filled /></div>
                        <div className="text">
                            ¡Y estás listo para comenzar a construir tu menú. Mucho éxito!
                            <div className="button-content"><button className="next-step" onClick={handleNextStep}>De acuerdo</button></div>
                        </div>
                    </div>
                }    

            </div>

        </div>   
    );
}