import React from "react";
import './Apologies.css';
import switchimage from '../../../assets/landing/Switch-apologies.svg';

const Apologies: React.FC = () => {
    return (
        <div className="apologies-container">
            <div className="apologies-content-wrapper">
                <h2>Sin disculpas.</h2>
                <div className="apologies-image-wrapper">
                    <img src={switchimage} alt="Sin disculpas" />
                </div>
                <p>
                    Evita darle a tus clientes una mala experiencia disculpándote por no tener algo disponible. <br />
                    Simplemente desactívalo y actívalo nuevamente cuando lo puedas ofrecer.
                </p>
            </div>
        </div>
    );
};

export default Apologies;
