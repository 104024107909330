import React, { useState, useContext } from 'react';
import { AuthContext } from '../../store/context/AuthContext';
import { AuthService } from '../../../services/auth/AuthService';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { RequieredLabel } from '../components/RequieredLabel/RequieredLabel';

interface LoginProps {
    onToggle: () => void;
}

export function Login({ onToggle }: LoginProps) {
    const { dispatchUser } : any = useContext(AuthContext);
    const [auth, setAuth] = useState({ email: '', password: '' });
    const [showPassword, setShowPassword] = useState(false);
    const [isEmailRequiered, setIsEmailRequiered] = useState(false);
    const [isPassRequiered, setIsPassRequiered] = useState(false);
    const [isAuth, setisAuth] = useState({status: false, text: ''})
    const navigate = useNavigate();
    
    const handleClick = async (e: React.MouseEvent) => {
        e.preventDefault();
        setisAuth({status: false, text: ''});
        try {
            
            if(auth.email === ''){
                setIsEmailRequiered(true);
            }else{
                setIsEmailRequiered(false);
            }

            if(auth.password === ''){
                setIsPassRequiered(true);
            }else{
                setIsPassRequiered(false);
            }

            if(!auth.email || !auth.password){
                return ;
            }

            const resp = await AuthService.login(auth);
            if (resp.success) {
                sessionStorage.setItem('user', JSON.stringify({ ...resp.data, loggedIn: true }));
                dispatchUser({ type: 'login', payload: resp.data });
                navigate('/dashboard/home');
            }

        } catch (error : any) {
            //alert(error.response.status)
            if (error.response && error.response.status === 401) {
                setisAuth({status: true, text: error.response.data.message});
            } else if(error.response && error.response.status === 403){
                localStorage.setItem('email', JSON.stringify( {'email' : auth.email }));
                navigate('/confirmation');
            } else{
                console.error(error);
            }
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAuth({ ...auth, [e.target.name]: e.target.value });
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    let textRequiered = 'Este campo es requerido.'

    return (
        <div className="center">
            <h2 className="form-title" id="login" onClick={onToggle}>Iniciar sesión</h2>
            <form>            
                <div className="form-holder">
                {isAuth ? <RequieredLabel text={isAuth.text}/> : null}
                    <input
                        type="email"
                        className={`${isEmailRequiered ? 'input-requiered' : 'input'}`}
                        placeholder="Email"
                        name="email"
                        value={auth.email}
                        onChange={handleChange}
                    />
                    {isEmailRequiered ? <RequieredLabel text={textRequiered}/> : null}
                    <div className="password-input-container">
                        <input
                            type={showPassword ? 'text' : 'password'}
                            className={`${isPassRequiered ? 'input-requiered' : 'input'}`}                            
                            placeholder="Password"
                            name="password"
                            value={auth.password}
                            onChange={handleChange}
                        />
                        <div className="password-icon" onClick={togglePasswordVisibility}>
                            {showPassword ? <FaEye /> : <FaEyeSlash />}
                        </div>
                    </div>
                    {isEmailRequiered ? <RequieredLabel text={textRequiered}/> : null}
                </div >
            </form>
            <button className="submit-btn" onClick={(e) => handleClick(e)}>Continuar</button>
        </div>
    );
}
