import './ProductFormModal.css';
import { Product, Price } from '../../../../../interfaces/Menu/Product';
import { IoClose } from 'react-icons/io5';
import { useState, useEffect } from 'react';
import { MenuService } from '../../../../../services/Menu/MenuService';
import { ToggleSwitch } from '../../Components/ToggleSwitch';
import { IoCloseCircle } from "react-icons/io5";
import Swal from 'sweetalert2';

interface ProductFormModalProps {
    closeModal: () => void;
    product: Product;
    isNew: boolean;
    updateProducts: (updatedProduct: Product) => void;
    categories: { id: number, name: string, status:boolean }[]
}

export function ProductFormModal({ closeModal, product, isNew, updateProducts, categories }: ProductFormModalProps) {
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [isPublished, setIsPublished] = useState<boolean>(product.status);
    const [isMultiprice, setIsMultiPrice] = useState<boolean>(product.is_multiprice);
    const [productId, setProductId] = useState(!isNew ? product.id || "" : "");
    const [image, setImage] = useState<string>(product.image ?? "");
    const [prices, setPrices] = useState<Price[]>(product.prices || []);
    const [productForm, setProductForm] = useState<Product>({
        id: product.id,
        category_id: product.category_id,
        name: product.name,
        description: product.description,
        is_multiprice: product.is_multiprice,
        price: product.price,
        prices: product.prices,
        status: product.status
    });



    const handleSaveProduct = async (e: React.MouseEvent) => {
        e.preventDefault();
        if (isNew && productId === "") {
            const resp = await MenuService.addProduct(productForm);
            if (resp.success) {
                Swal.fire({
                    title: resp.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 3000
                });
                updateProducts(resp.data);
                setProductForm({ ...productForm, id: resp.data.id });
                setProductId(resp.data.id);
            }
        } else {
            const resp = await MenuService.updateProduct(productForm);
            if (resp.success) {
                Swal.fire({
                    title: resp.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 3000
                });
                updateProducts(resp.data);
                setProductForm({ ...productForm, id: resp.data.id });
                setProductId(resp.data.id);
            }
        }
    };

    const handleTogglePublished = () => {
        setProductForm({ ...productForm, status: !isPublished });
        setIsPublished(!isPublished);
    };


    const handleToggleMultiprice = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { value } = e.target;
        const multiprice = Number(value) == 1 ? true : false;
        setProductForm({ ...productForm, is_multiprice: multiprice });
        setIsMultiPrice(!isMultiprice);
    };


    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setProductForm(prevForm => ({
            ...prevForm,
            [name]: name === 'price' ? parseFloat(value) : value
        }));
    };

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            if (productId) {
                const formData = new FormData();
                formData.append('image', file);
                formData.append('product_id', productId);
                const resp = await MenuService.addProductImage(formData);
                if (resp.success) {
                    Swal.fire({
                        title: resp.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 3000
                    });
                    setImage(URL.createObjectURL(file));
                } else {
                    Swal.fire({
                        title: 'Error al subir la imagen',
                        icon: 'error',
                        showConfirmButton: true,
                    });
                }
            }
        }
    };

    const handleAddPrice = () => {
        setPrices([...prices, { name: '', price: 0 }]); // Agrega un nuevo par vacío
    };
    
    const handlePriceChange = (index: number, field: string, value: string | number) => {
        const updatedPrices = prices.map((price, i) => 
            i === index ? { ...price, [field]: field === 'price' ? parseFloat(value as string) : value } : price
        );
        setPrices(updatedPrices);
        setProductForm({ ...productForm, prices: updatedPrices }); // Cambia esto para usar updatedPrices
    };

    const handleRemovePrice = (index: number) => {
        setPrices(prices.filter((_, i) => i !== index));
    };
    

    return (
        <div className="product-form-modal">
            <div className="product-form-modal-content">
                <div className='modal-columns'>
                    <div className='modal-column-1'>
                    <button className="close-btn" onClick={closeModal}><IoClose /></button>
                    <h3>{isNew ? "Agregar Producto" : "Editar Producto"}</h3>
                    <form>
                        <label htmlFor="product-name">Nombre</label>
                        <input
                            id="product-name"
                            type="text"
                            value={productForm.name}
                            placeholder="Nombre del producto"
                            name='name'
                            required
                            onChange={handleChange}
                        />
                        <label htmlFor="product-description">Descripción</label>
                        <textarea
                            id="product-description"
                            rows={4}
                            value={productForm.description}
                            name='description'
                            placeholder="Descripción del producto"
                            onChange={handleChange}
                            maxLength={800}
                        />
                        <label htmlFor="is_multiprice">¿Manejas diferentes precios para diferentes tamaños de este producto?</label>
                        <select
                            id="is_multiprice"
                            name='is_multiprice'
                            className='form-input'
                            value={productForm.is_multiprice ? '1' : '0'}
                            onChange={handleToggleMultiprice}
                            required
                        >
                            <option value="0">No</option>
                            <option value="1">Sí</option>
                        </select>
                        <br />
                        {!isMultiprice &&  
                        <div>
                            <label htmlFor="product-price">Precio</label>
                            <input
                                id="product-price"
                                type="number"
                                step="0.01" // Permite dos dígitos decimales
                                value={productForm.price}
                                placeholder="Precio del producto"
                                name='price'
                                required
                                onChange={handleChange}
                            />
                        </div>                       

                        }
                        
                        {isMultiprice && 
                            <div className='multiprice'>
                                <label htmlFor="product-prices">Tamaño / Precios</label>
                                {prices.map((price, index) => (
                                    <div key={index} className='multiprices-inputs'>
                                        <input
                                            type="text"
                                            value={price.name}
                                            placeholder="Tamaño"
                                            name='name'
                                            required
                                            onChange={(e) => handlePriceChange(index, 'name', e.target.value)}
                                        />
                                        <input
                                            type="number"
                                            step="0.01"
                                            value={price.price}
                                            placeholder="Precio"
                                            name='amount'
                                            required
                                            onChange={(e) => handlePriceChange(index, 'price', e.target.value)}
                                        />
                                        <button type="button" onClick={() => handleRemovePrice(index)}>
                                            <IoCloseCircle className='trash-price' />
                                        </button>
                                    </div>
                                ))}
                                <button type="button" className='submit-btn' onClick={handleAddPrice}>Agregar tamaño/precio</button>
                            </div>
                        }

                        <label htmlFor="product-category">Categoría</label>
                        <select
                            id="product-category"
                            name='category_id'
                            className='form-input'
                            value={productForm.category_id || ''}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Selecciona una categoría</option>
                            {categories.map(category => (
                                <option key={category.id} value={category.id}>{category.name}</option>
                            ))}
                        </select>
                        <ToggleSwitch
                            label="Activo"
                            checked={isPublished}
                            onChange={handleTogglePublished}
                        />
                        </form>
                    </div>
                    <div className='modal-column-2'>
                        {productId &&
                                <div className="image-product">
                                    {image ? <img src={image} alt="Product" /> : <div className='empty-image'>Agrega una imagen...</div>}
                                    <button className="upload-btn" >
                                        <label htmlFor="image-upload" style={{ cursor: 'pointer' }}>Subir imagen</label>
                                        <input
                                            type="file"
                                            id="image-upload"
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange}
                                            accept="image/*"
                                        />
                                    </button>
                                </div>
                            }
                    </div>
                </div>
                <div className='save-product'>
                    <button className='submit-btn' type="submit" onClick={(e) => handleSaveProduct(e)}>Guardar</button>
                </div>

            </div>
        </div>
    );
}
