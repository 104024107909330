import { useEffect, useState } from 'react';
import './Roadmap.css';


interface RoadmapProps {
    activeStep: number;  // Define el paso activo (1 para General, 2 para Banners, etc.)
  }
  
const steps = ['General', 'Banners', 'Categoría', 'Productos'];
const stepClass = ['general', 'banners', 'category', 'products'];

export function Roadmap({ activeStep }: RoadmapProps) {

    return (
        <div className="roadmap-content">
            <div className="line"></div> {/* Línea vertical */}
            {steps.map((step, index) => (
            <div 
                key={index} 
                className={`step ${activeStep === index + 1 ? 'active' : ''} ${stepClass[index]}`}
            >
                <h3>{step}</h3>
            </div>
            ))}
      </div>
    );
}
