import './VentureSection.css';
import { useEffect, useState } from 'react';
import Swal from "sweetalert2";
import noImage from "../../../../../assets/img/no-image.jpg"
import { Branches } from '../../Components/Branches/Branches';
import { Venture } from '../../../../../interfaces/Venture/Venture';
import { Branch } from '../../../../../interfaces/Venture/Branch';
import { VentureService } from '../../../../../services/VentureService/VentureService';
import { useNavigate } from 'react-router-dom';

export function VentureSection() {
    const [venture, setVenture] = useState<Venture>({
        name: '', // Nombre de la sucursal
        keywords: '',
        description: '',
    });

    const [branches, setBranches] = useState<Branch[]>([]);
    const [image, setImage] = useState<string>(noImage);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchInformation = async () => {
            const response = await VentureService.getVentureInformation();
            const venture = response.data.venture;
            const branches = response.data.branches.map((branch: Branch) => ({
                id: branch.id,
                name: branch.name,
            }));
            
            if(venture.image){
                setImage(venture.image);
            }

            setVenture(venture);
            setBranches(branches);
        };
        fetchInformation();
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setVenture({ ...venture, [e.target.name]: e.target.value });
    };

    const handleChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setVenture({ ...venture, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const resp = await VentureService.setVenture(venture);
        if (resp.success) {
            Swal.fire({
                title: resp.message,
                icon: 'success',
                showConfirmButton: false,
                timer: 3000
            });

            setTimeout(function() {
                navigate('/');
            }, 2500);
        }
    };

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            
            // Crear un objeto FormData
            const formData = new FormData();
            formData.append('image', file);  // 'image' debe coincidir con lo que espera tu backend
    
            // Enviar la imagen usando FormData
            const resp = await VentureService.setVentureImage(formData);
            if (resp.success) {
                Swal.fire({
                    title: resp.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 3000
                });
                setImage(URL.createObjectURL(file)); // Actualizar la imagen mostrada
            } else {
                Swal.fire({
                    title: 'Error al subir la imagen',
                    icon: 'error',
                    showConfirmButton: true,
                });
            }
        }
    };

    const handleUploadImage: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();
        document.getElementById('fileInput')?.click();
    };

    const updateBranches = (branches: Branch[]) => {
        setBranches(branches);
      };

    return (
        <div className="venture">
            <div className='venture-info'>
                <div className='form-venture form'>
                    <form className='principal-info'>
                        <div className="image-container">
                            <div className='image-input'>
                                <img src={image} alt="Venture" />
                                <button className='upload-logo' onClick={handleUploadImage} >Actualizar/cargar mi logo</button>
                            </div>
                            
                            <div className='file-input-content'>
                                <input 
                                    id="fileInput" 
                                    type="file" 
                                    className="file-input" 
                                    onChange={handleFileChange} 
                                />
                            </div>
                            
                        </div>
                        
                        <input type="text" className="" placeholder="Nombre" name="name" onChange={handleChange} value={venture.name ?? ""} />
                        <input type="text" className="" placeholder="Keywords" name="keywords" onChange={handleChange} value={venture.keywords ?? ""} />
                        <textarea name="description" placeholder='Descripción' onChange={handleChangeTextArea} value={venture.description ?? ""}></textarea>
                    </form>
                    <Branches branches={branches} updateBranches={updateBranches}/>
                </div>
                <div className='save-btn'>
                    <button className="submit-btn" onClick={handleSubmit}>Guardar</button>
                </div>
            </div>
        </div>
    );
}
