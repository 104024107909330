import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { AuthService } from '../../../services/auth/AuthService';
import { RequieredLabel } from '../components/RequieredLabel/RequieredLabel';
import { FaEye, FaEyeSlash } from 'react-icons/fa';


interface RegisterProps {
    onToggle: () => void;
}

export function Register({ onToggle }: RegisterProps) {
    const [auth, setAuth] = useState({ name: '', email: '', password: '' });
    const [isNameRequiered, setIsNameRequiered] = useState(false);
    const [isEmailRequiered, setIsEmailRequiered] = useState(false);
    const [isPassRequiered, setIsPassRequiered] = useState(false);
    const [existsAccount, setExistsAccount] = useState({status: false, text: ''})
    const [showPassword, setShowPassword] = useState(false);

    const navigate = useNavigate();
    let text = '';

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            setExistsAccount({status: false, text: 'Este campo es requerido.'});
            if(auth.name === ''){
                setIsNameRequiered(true);
            }else{
                setIsNameRequiered(false);
            }
            
            if(auth.email === ''){
                setIsEmailRequiered(true);
            }else{
                setIsEmailRequiered(false);
            }

            if(auth.password === ''){
                setIsPassRequiered(true);
            }else{
                setIsPassRequiered(false);
            }
            
            if(!auth.name || !auth.email || !auth.password){
                return ;
            }

            const resp = await AuthService.register(auth);
            if (resp.success) {
                localStorage.setItem('email', JSON.stringify( {'email' : resp.data.email }));
                navigate('/confirmation');
            }

        } catch (error : any) {
            if (error.response && error.response.status === 409) {
                text = error.response.data.message;
                setExistsAccount({status: true, text: text});
            } else {
                console.error(error);
            }
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAuth({ ...auth, [e.target.name]: e.target.value });
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div>
            <h2 className="form-title" id="signup" onClick={onToggle}>Registrarme</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-holder">
                    <input
                        type="text"
                        className={`${isNameRequiered ? 'input-requiered' : 'input'}`}
                        placeholder="Name*"
                        name="name"
                        value={auth.name}
                        onChange={handleChange}
                    />
                    {isNameRequiered ? <RequieredLabel text={existsAccount.text}/> : null}
                    <input
                        type="email"
                        className={`${isEmailRequiered ? 'input-requiered' : 'input'}`}
                        placeholder="Email*"
                        name="email"
                        value={auth.email}
                        onChange={handleChange}
                    />
                    {isEmailRequiered ? <RequieredLabel text={existsAccount.text}/> : null}
                    {existsAccount.status ? <RequieredLabel text={existsAccount.text}/> : null}
                    <div className="password-input-container">
                        <input
                            type={showPassword ? 'text' : 'password'}
                            className={`${isPassRequiered ? 'input-requiered' : 'input'}`}
                            placeholder="Password*"
                            name="password"
                            value={auth.password}
                            onChange={handleChange}
                        />
                        <div className="password-icon" onClick={togglePasswordVisibility}>
                            {showPassword ? <FaEye /> : <FaEyeSlash />}
                        </div>
                    </div>
                    {isPassRequiered ? <RequieredLabel text={existsAccount.text}/> : null}
                </div>
                <button className="submit-btn">Continuar</button>
            </form>
        </div>
    );
}
