import { useNavigate, useParams } from "react-router-dom";
import './SuccessConfirmation.css'
import logo from '../../../assets/img/logo.png'
import { FaCircleCheck } from "react-icons/fa6";
import { AuthService } from '../../../services/auth/AuthService';
import { useEffect } from "react";



export function SuccessConfirmation() {
    const { data } = useParams<{ data: string }>();
    
    const navigate = useNavigate();

    useEffect(() => {
        const confirmAccount = async () => {
            if (data) { 
                try {
                    const resp = await AuthService.confirm({'token': data});
                    
                    if(!resp.success){
                        localStorage.setItem('email', JSON.stringify( {'email' : resp.data.email }));
                        navigate('/confirmation-expired');
                    }
                } catch (error) {
                    console.error(error);
                }
            }else{
                navigate('/auth');
            }
        };

        confirmAccount();

        
    },[]);
    
    const handleClick = async (e: React.FormEvent) => {
        e.preventDefault();
        navigate('/auth');
    };

    
    return (
        <div className="success-confirmation">
                <div className='msg-container'>
                    <img src={logo} alt="" />

                    <div className="subtitle">¡Felicidades! Tu cuenta ha sido verificada.</div>
                    
                    <FaCircleCheck className='icon-confirm'/>

                    <div className="description">
                        Para continuar, ahora solo inicia sesión.
                    </div>

                    <button className="submit-btn" onClick={handleClick}>Iniciar sesión</button>
                
                </div>
        </div>
    );
}

