import { useNavigate} from "react-router-dom";
import './ExpiredConfirmation.css'
import logo from '../../../assets/img/logo.png'
import { MdTimer } from "react-icons/md";
import { AuthService } from '../../../services/auth/AuthService';
import Swal from "sweetalert2";



export function ExpiredConfirmation() {
    
    
    const navigate = useNavigate();
    
    const handleClick = async (e: React.FormEvent) => {
        e.preventDefault();
        let emailSession: any = localStorage.getItem('email');
        let email: any = JSON.parse(emailSession);
        console.log(email)
        const resp = await AuthService.resendMail(email);
        if(resp.success){
            Swal.fire({
                title: resp.message,
                icon: 'success',
                showConfirmButton: false,
                timer: 3000
            });
        }
        
    };

    
    return (
        <div className="success-confirmation">
                <div className='msg-container'>
                    <img src={logo} alt="" />

                    <div className="subtitle">Lo sentimos, tu token de confirmación ha expirado :(</div>
                    
                    <MdTimer className='icon-confirm'/>

                    <div className="description">
                    Puede que la cuenta ya haya sido confirmada o que tu token haya expirado. Puedes solicitar el reenvío del correo de confirmación, pero si la cuenta ya ha sido confirmada, no recibirás ningún correo.
                    </div>

                    <button className="submit-btn" onClick={handleClick}>Reenviar correo</button>
                
                </div>
        </div>
    );
}

