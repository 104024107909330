import React from 'react';
import Welcome from './welcome/Welcome';
import WhyUs from './WhyUs/WhyUs';
import Divider from './components/Divider/Divider';
import Anywhere from './anywhere/Anywhere';
import Yourself from './Yourself/Yourself';
import Apologies from './Apologies/Apologies';
import Subscription from './Subscription/Subscription';
import Footer from './Footer/Footer';
import "./Landing.css"

const Landing: React.FC = () => {
  return (
    <div className="landing-container">
        <Welcome/>
        <div className="landing-body">
          <WhyUs/>
          <Divider/>
          <Anywhere/>
          <Divider/>
          <Yourself/>
          <Divider/>
          <Apologies/>
          <Divider/>
          <Subscription/>
        </div>
        <br />
        <Footer/>
    </div>
  );
};

export default Landing;
