import './CreateOrEditBranch.css';
import { useEffect, useState } from 'react';
import { Gallery } from '../../Components/Gallery/Gallery';
import { Editor } from '@tinymce/tinymce-react';
import { FormBranch } from '../../Components/FormBranch/FormBranch';
import { BranchService } from '../../../../../services/VentureService/BranchService';
import { Branch } from '../../../../../interfaces/Venture/Branch';
import { env } from '../../../../../../env';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';

export function CreateOrEditBranch(){
    const { branchId: paramBranchId } = useParams();
    const [branchId, setBranchId] = useState<string>(paramBranchId || '');
    const [branch, setBranch] = useState<Branch>({
        id: null,
        name: '', // Nombre de la sucursal
        city: null,
        street: '',
        external_number: null,
        internal_number: null,
        cp: '',
        suburb: '',
        country: 142,
        state: null,
        phone: '',
        content: '',
    });

    useEffect(() => {
        if (branchId) {
            const fetchInformation = async () => {
                const response = await BranchService.getBranch(Number(branchId))
                const branch = {
                    id: response.data.id,
                    name: response.data.name,
                    city: response.data.city,
                    street: response.data.street,
                    external_number: response.data.external_number,
                    internal_number: response.data.internal_number,
                    cp: response.data.cp,
                    suburb: response.data.suburb,
                    country: response.data.country,
                    state: response.data.state,
                    phone: response.data.phone,
                    content: response.data.content,
                };
                
                setBranch(branch);
              };
              fetchInformation();
        }
    }, [branchId]);
    

    const navigate = useNavigate();
    
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setBranch({ ...branch, [e.target.name]: e.target.value });
    };

    const handleEditorChange = (content: string) => {
        setBranch({ ...branch, content });
    };

    const handleSubmit =  async (e: React.FormEvent) => { 
        e.preventDefault();
        if(branchId){
            const resp = await BranchService.updateBranch(branch);
            
            if (resp.success) {
                Swal.fire({
                    title: resp.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 3000
                });

                setTimeout(function() {
                    navigate('/dashboard/venture');
                }, 2500);
                
            }
            
        }else{
            const resp = await BranchService.newBranch(branch);
            
            if (resp.success) {
                Swal.fire({
                    title: resp.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 3000
                });
                setBranchId(String(resp.data.id));


            }
        }
    };

    return (
        <div className="branch-add-or-create">
            <h2>{branchId ? 'Editar sucursal' : 'Nueva sucursal'}</h2>
                <form onSubmit={handleSubmit}>
                    <FormBranch branch={branch} handleChange={handleChange} />

                    <div className='editor'>
                        <h3>Contenido</h3>
                        <div className='editor-box'>
                            <Editor
                                apiKey={env.tokenTinyMCE}
                                init={{
                                    plugins: 'autolink charmap codesample emoticons image link lists media table visualblocks wordcount',
                                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                    tinycomments_mode: 'embedded',
                                    mergetags_list: [
                                        { value: 'First.Name', title: 'First Name' },
                                        { value: 'Email', title: 'Email' },
                                    ],
                                }}
                                value={branch.content}  
                                onEditorChange={handleEditorChange}
                            />
                        </div>
                    </div>

                    {branchId && <Gallery branchId={branchId}/> }
                    
                    <div className='submit-branch-button'>
                        <button className="submit-btn">Guardar</button>
                    </div>
                </form>
        </div>   
    );
}
