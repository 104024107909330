import { PhotoName } from '../PhotoName/PhotoName';
import './MenuDesktop.css';
import { ListOptions } from '../ListOptions/ListOptions';

export function MenuDesktop() {
    

    return (
        <div className='menu-desktop'>
        <PhotoName/>
        <nav className='card'>
            <ListOptions/>   
        </nav>
    </div>
    );
}
