import { useEffect, useState } from 'react';
import './CategoriesSection.css';
import noImage from '../../../../assets/img/no-image.jpg';
import { CategoryFormModal } from './CategoryFormModal/CategoryFormModal';
import { Category } from '../../../../interfaces/Menu/Category';
import { MenuService } from '../../../../services/Menu/MenuService';
import Swal from 'sweetalert2';

export function CategoriesSection(){
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [isNew, setIsNew] = useState<boolean>(true);
    const [categories, setCategories] = useState<Category[]>([]);
    const [categoryOpen, setCategoryOpen] = useState<Category>({
        id:"",
        name: "",
        description: "",
        image: "",
        status: false
    });

    useEffect(() => {
        const fetchInformation = async () => {
            const response = await MenuService.getCategories();
            const categories = response.data.map((category: Category) => ({
                id: category.id,
                name: category.name,
                description: category.description,
                image: category.image,
                status: category.status
            }));
            setCategories(categories);
        };
        fetchInformation();
    }, []);

    // Función para actualizar o agregar una nueva categoría
    const updateCategories = (updatedCategory: Category) => {
        setCategories(prevCategories => {
            const categoryExists = prevCategories.some(category => category.id === updatedCategory.id);
            if (categoryExists) {
                // Si la categoría ya existe, la actualizamos
                return prevCategories.map(category =>
                    category.id === updatedCategory.id ? updatedCategory : category
                );
            } else {
                // Si la categoría no existe, la agregamos al listado
                return [...prevCategories, updatedCategory];
            }
        });
    };
    
    const closeModal = () => {
        setIsFormModalOpen(false);
    };
    const handleDeleteCategory = (id: string | undefined) => {

        if (!id) {
            console.error("ID de categoría no proporcionado");
            return;
        }
        
        Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#000',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
          }).then(async (result) => {
            if (result.isConfirmed) {
              try {
                // Llama al servicio para eliminar la imagen
                const resp = await MenuService.deleteCategory(id);
                if(resp.success){
                  const newCategories = categories.filter(category => category.id !== id);
                  setCategories(newCategories);
          
                  // Muestra un mensaje de éxito
                  Swal.fire({
                        title:'¡Eliminado!',
                        text: resp.message,
                        icon:'success',
                        confirmButtonColor: '#000',
                    }
                  );
                }
               
  
              } catch (error) {
                console.error('Error eliminando la imagen:', error);
                Swal.fire({
                  title:'Error',
                  text:'Hubo un problema al eliminar la imagen.',
                  icon:'error',
                  confirmButtonColor: '#000',
                });
              }
            }
          });
        };
    
    
    const handleOpenForm = (e: React.FormEvent, isNew: boolean, id: string | undefined) => {
        e.preventDefault();
        setIsNew(isNew);
        setIsFormModalOpen(true);
        if(id){
            const category = categories.find(category => category.id === id);
            if (category) {
                setCategoryOpen(category);  // Si se encuentra, establecemos la categoría abierta.
            } else {
                setCategoryOpen({
                    id: "",
                    name: "",
                    description: "",
                    image: "",
                    status: false
                });
            }
        }else{
            setCategoryOpen({
                id: "",
                name: "",
                description: "",
                image: "",
                status: false
            });
        }
      };
    
    return (
        <div className="categories">
            <h2>Categorías</h2>
            <div className='btn-add-category'>
                <button className='submit-btn' onClick={(e) => handleOpenForm(e, true, "")}>Agregar +</button>
            </div>

            <div className='categories-cards'>
                {categories.length > 0
                    ?  
                    categories.map((category) => (
                    <div key={category.id} className='category-card card'>
                        <img src={category.image != "" ? category.image : noImage } alt="category-image" />
                        <div className='category-info'>
                            <div className='category-text'>
                            <h3>{category.name}</h3>
                            <p>{category.description}</p>
                            </div>
                            
                        </div>
                        <div className='btn-action-group'>
                            <button className='edit-category' onClick={(e) => handleOpenForm(e, false, category.id)}>Editar</button>
                            <button className='delete-category' onClick={(e) => handleDeleteCategory(category.id)}>Eliminar</button>
                        </div>
                    </div>
                    ))
                    :
                    <div>No cuentas con categorías agregadas:(</div>
                }
            </div>

            {isFormModalOpen && (
                <CategoryFormModal 
                closeModal={closeModal} 
                category={categoryOpen} 
                isNew={isNew}
                updateCategories={updateCategories}
                />
            )}

        </div>
    );
}
