import { useEffect, useState } from 'react';
import './DesignMenuSection.css';
import { Roadmap } from './Roadmap/Roadmap';

export function DesignMenuSection(){
    const [step, setStep] = useState<number>(1)

    const handleNextStep = () => {
        setStep(step + 1);
    };

    const handlePreStep = () => {
        setStep(step - 1);
    };

    return (
        <div className="design-menu-section">
            <h1>Mi Menú</h1>
            <Roadmap activeStep={step} />

            <div className='form-menu'>
                <button className='submit-btn' onClick={handlePreStep}>Atrás</button><br />
                <button className='submit-btn' onClick={handleNextStep}>Continuar</button>
            </div>
        </div>
    );
}
