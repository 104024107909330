import "./Branches.css"
import { MdModeEdit, MdDelete } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { Branch } from "../../../../../interfaces/Venture/Branch";
import Swal from "sweetalert2";
import { BranchService } from "../../../../../services/VentureService/BranchService";

interface BranchesProps {
    branches: Branch[];
    updateBranches: (branch: Branch[]) => void;
}


export function Branches({ branches, updateBranches }: BranchesProps){

    const navigate = useNavigate();
    
    const navigateToNewBranch = () => {
        navigate('/dashboard/venture/new-branch');
    };

    const navigateToEditBranch = (branchId: number) => {
        navigate(`/dashboard/venture/edit-branch/${branchId}`);
    };

    const handleDeleteBranch = (e: React.MouseEvent<HTMLButtonElement>, id: number) => {
        e.preventDefault();
    
        Swal.fire({
          title: '¿Estás seguro?',
          text: "¡No podrás revertir esto!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#000',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí, eliminar',
          cancelButtonText: 'Cancelar'
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              // Llama al servicio para eliminar la imagen
              const resp = await BranchService.deleteBranch(id);
              if(resp.success){
                const updatedImages = branches.filter(branch => branch.id !== id);
                updateBranches(updatedImages);
        
                // Muestra un mensaje de éxito
                Swal.fire({
                      title:'¡Eliminado!',
                      text: resp.message,
                      icon:'success',
                      confirmButtonColor: '#000',
                  }
                );
              }
             

            } catch (error) {
              console.error('Error eliminando la imagen:', error);
              Swal.fire({
                title:'Error',
                text:'Hubo un problema al eliminar la imagen.',
                icon:'error',
                confirmButtonColor: '#000',
              });
            }
          }
        });
      };

    return (
        <div className='branches'>
            <h1 className='title'>Sucursales</h1>
            <div className="add-branch">
                <button onClick={navigateToNewBranch}>Agregar sucursal +</button>
            </div>
            {
                branches.length > 0
                ?  
                branches.map((branch) => (
                    <div key={branch.id} className='branch card'>
                        <h2>{branch.name}</h2>
                        <div className="btn-group">
                            <button onClick={() => navigateToEditBranch(Number(branch.id))}>
                                <MdModeEdit className="edit" />
                            </button>
                            <button onClick={(e) =>  handleDeleteBranch(e, Number(branch.id))}>
                                <MdDelete className="delete"/>
                            </button>
                        </div>
                    </div>
                ))
                :
                <div>No cuentas con sucursales agregadas:(</div>
            }
            
        </div>
    );
}