import React from "react";
import './Yourself.css';
import pencils from '../../../assets/landing/pencils 1.svg';
import sucursal from '../../../assets/landing/susucrsal 1.svg';
import star from '../../../assets/landing/star 1.svg';
import libro from '../../../assets/landing/libro 1.svg';

const Yourself: React.FC = () => {
    return (
        <div className="yourself-container">
            <div className="yourself-content-wrapper">
                <h2>Hazlo tú mismo.</h2>
                <p>
                    Nosotros te damos los pinceles, tú solo lo construye y le das <span className="highlight-color">COLOR.</span>
                </p>
                <div className="yourself-image-grid">
                    <div className="yourself-image-item">
                        <img src={pencils} alt="Personalización" />
                        <p>Dale tu toque personal y estilízalo a tu manera.</p>
                    </div>
                    <div className="yourself-image-item">
                        <img src={sucursal} alt="Sucursales" />
                        <p>Muestra información y fotografías de cada una de tus sucursales.</p>
                    </div>
                    <div className="yourself-image-item">
                        <img src={star} alt="Profesional" />
                        <p>Dale una vista más profesional e interactiva a tu negocio.</p>
                    </div>
                    <div className="yourself-image-item">
                        <img src={libro} alt="Atracción" />
                        <p>Más atracción para conocer todo lo que puedes ofrecer.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Yourself;
