import React from "react";
import './Anywhere.css';
import anywherephoto from '../../../assets/landing/cafeNmainu.svg';

const Anywhere: React.FC = () => {
    return (
        <div className="anywhere-container">
            <div className="anywhere-image">
                <img src={anywherephoto} alt="Anywhere" />
            </div>
            <div className="anywhere-content">
                <h2>Desde cualquier lugar...</h2>
                <p>
                    Permite que te conozcan y comparte lo que haces con todos fácilmente
                    a través del QR o el link que generaremos de tu menú.
                </p>
            </div>
        </div>
    );
};

export default Anywhere;
