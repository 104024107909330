import { ApiResponse } from "../../interfaces/response/ApiResponse";
import { User } from "../../interfaces/user/User";
import { AppService } from "../AppService";

export class AuthService{

    public static async login(obj:User):Promise<ApiResponse>{
        return (await AppService.post('/login', obj)).data;
    }

    public static async register(obj:User):Promise<ApiResponse>{
        return (await AppService.post('/register', obj)).data;
    }

    public static async confirm(obj:any):Promise<ApiResponse>{
        return (await AppService.post('/confirmation', obj)).data;
    }

    public static async resendMail(obj:any):Promise<ApiResponse>{
        return (await AppService.post('/resend-verify', obj)).data;
    }
}