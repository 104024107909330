import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import './Gallery.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ImageUploadModal } from '../ImageUploadModal/ImageUploadModal';
import { BranchImage } from '../../../../../interfaces/Venture/BranchImage';
import { BranchService } from '../../../../../services/VentureService/BranchService';

export function Gallery({ branchId } : {branchId: string | undefined}): JSX.Element {
  const [images, setImages] = useState<BranchImage[]>([]);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  useEffect(() => {
    const fetchImages = async () => {
      const response = await BranchService.getBranchImages(Number(branchId));
      const imagesList = response.data.map((image: BranchImage) => ({
        id: image.id,
        photo: image.photo,
      }));
      setImages(imagesList);
    };
    if(branchId){
      fetchImages();
    }
    

  }, []);

  const updateBranchImages = (images: BranchImage[]) => {
    setImages(images);
  };

  const openModal = (image: string) => {
    setSelectedImage(image);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedImage(null);
  };

  const openImageUploadModal = (e: React.FormEvent) => {
    e.preventDefault();
    setIsUploadModalOpen(true);
  };

  const closeImageUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  return (
    <div className='gallery'>
      <h3>Galería</h3>
      <div className="add-image">
        <button onClick={openImageUploadModal}>Agregar/editar mi galería +</button>
      </div>
      <div className='gallery-slider'>
        
          { images.length > 0 ?
              <Slider {...settings} className='gallery-collection'>
                {images.map((image, index) => (
                  <div key={index} className='gallery-image' onClick={() => openModal(image.photo)}>
                    <img src={image.photo} alt={`Gallery image ${image.id}`} />
                  </div>
                ))}
            </Slider>
            
            :
            <div>Aún no cuentas con galería de fotos para esta sucursal</div>
      
          }
        

        {isOpen && selectedImage && (
          <div className='modal' onClick={closeModal}>
            <span className='close'>&times;</span>
            <img className='modal-content' src={selectedImage} alt='Selected' />
          </div>
        )}
      </div>
      
      {isUploadModalOpen && (
        <ImageUploadModal 
          closeModal={closeImageUploadModal} 
          images={images} 
          updateBranchImages={updateBranchImages} 
          branchId={branchId}
        />
      )}
    </div>
  );
}
