

export interface Action{
    type:string,
    payload:any
}

const isAuthenticated = {loggedIn: false}

export function authReducer(state = isAuthenticated, action:Action){
    switch(action.type){
        case 'login':
            return {...action.payload, loggedIn:true};
        case 'logout':
            return {loggedIn:false};
        case 'update_profile_image':
            return { ...state, image: action.payload }; 
        default:
            return state;
    }
}