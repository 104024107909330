import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { Home } from "./Home/Home";
import { Header } from "./Header/Header";
import { VentureRouter} from "./Venture/VentureRouter";
import { MenuRouter } from "./Menu/MenuRouter"
import { ProfileSection } from "./Options/Profile/ProfileSection";
import { SubscriptionsRouter } from "./Subscriptions/SubscriptionsRouter";
import { useEffect, useState } from "react";
import { DesignMenuSection } from "./Menu/Design/DesignMenuSection";

export function DashboardRouter() {
    const location = useLocation();
    const [isNewUser, setIsNewUser] = useState<boolean>(false)
    useEffect(() => {
        if (location.state?.from == "/dashboard/subscriptions/payment-success") {
            setIsNewUser(true);
        }else{
            setIsNewUser(false); 
        }
    }, [location.state]);

    return (
        <main>
            <Header/>
            <div className="content-body">
                <div className="container-fluid">
                    <Routes>
                        <Route path="home" element={<Home isNewUser={isNewUser}/>} />
                        <Route path="venture/*" element={<VentureRouter />} />
                        <Route path="menu/*" element={<MenuRouter />} />
                        <Route path="profile" element={<ProfileSection />} />
                        <Route path="profile" element={<ProfileSection />} />
                        <Route path="design-menu/*" element={<DesignMenuSection />} />
                        <Route path="*" element={<Navigate to="home" />} />
                    </Routes>
                </div>
            </div>
        </main>
    );
}

