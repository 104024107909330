import React, { useState, useEffect } from 'react';
import './FormBranch.css';
import PhoneInput from 'react-phone-input-2';
import { ResourcesService } from '../../../../../services/resources/ResourcesService';
import 'react-phone-input-2/lib/style.css';
import { Country } from "../../../../../interfaces/resources/Country";
import { State } from '../../../../../interfaces/resources/State';
import { Branch } from '../../../../../interfaces/Venture/Branch';
import { City } from '../../../../../interfaces/resources/City';

export function FormBranch({ branch, handleChange }: { branch: Branch, handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void }) {
  const [phone, setPhone] = useState<string>(branch.phone);
  const [countries, setCountries] = useState<Country[]>([]);
  const [states, setStates] = useState<State[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  

  useEffect(() => {
    const fetchCountries = async () => {
      const response = await ResourcesService.countries();
      const countryList = response.data.map((country: Country) => ({
        country_id: country.country_id,
        name: country.name,
        emoji: country.emoji
      }));
      setCountries(countryList);
    };
    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchStates = async () => {
      const response = await ResourcesService.states(Number(branch.country));
      const stateList = response.data.map((state: State) => ({
        id: state.id,
        name: state.name,
        country: state.country,
        state_code: state.state_code,
        type: state.type
      }));
      setStates(stateList);
    };
    fetchStates();
  }, [branch.country]);

  useEffect(() => {
    const fetchCities = async () => {
      // Encuentra el estado seleccionado
      const selectedState = states.find(state => state.id === Number(branch.state));
      
      // Verifica si se encontró un estado antes de intentar hacer la petición
      if (selectedState) {
        const response = await ResourcesService.cities(selectedState.state_code);
        const citiesList = response.data.map((city: City) => ({
          id: city.id,
          name: city.name,
        }));
        setCities(citiesList);
      } else {
        // Maneja el caso en que no se encontró el estado
        setCities([]); // Opcional: Limpia la lista de ciudades si no se encontró el estado
      }
    };
    fetchCities();
  }, [branch.state, states]); 
  
  return (
    <div className='ubication'>
      <h3>Ubicación</h3>
      <div className='form'>
        <div className='column-1'>
          <input
            type="text"
            name="name"
            value={branch.name}
            onChange={handleChange}
            placeholder="Nombre de la sucursal*"
          />
          <div className='input-phone'>
            <PhoneInput
              country={'mx'}
              value={branch.phone}
              onChange={(phone: string) => {
                setPhone(phone);
                handleChange({ target: { name: 'phone', value: phone } } as React.ChangeEvent<HTMLInputElement>);
              }}
              placeholder="Teléfono"
            />
          </div>
          <select 
            name="country" 
            value={branch.country ?? 142} 
            onChange={handleChange} 
            className="form-input">
            {countries.map((country) => (
              <option key={country.country_id} value={country.country_id}>
                {country.emoji} {country.name}
              </option>
            ))}
          </select>
          <select
            name="state"
            value={branch.state ?? ''}
            onChange={handleChange}
            className="form-input"
          >
            <option className='default-option'>Selecciona tu estado</option>
            {states.map((state) => (
              <option key={state.id} value={state.id}>
                {state.name}
              </option>
            ))}
          </select>
          <select
            name="city"
            value={branch.city ?? ''}
            onChange={handleChange}
            className="form-input"
          >
            <option className='default-option'>Selecciona tu ciudad</option>
            {cities.map((city) => (
              <option key={city.id} value={city.id}>
                {city.name}
              </option>
            ))}
          </select>
        </div>
        <div className='column-2'>
          <input
            type="text"
            name="street"
            value={branch.street}
            onChange={handleChange}
            placeholder="Calle*"
          />
          <div className='numbers-input'>
            <input 
              type="number" 
              className="external_number" 
              placeholder="Numero exterior*" 
              name="external_number" 
              value={branch.external_number ?? ''} 
              onChange={handleChange}
            />

            <input 
              type="number" 
              className="internal_number" 
              placeholder="Numero interior" 
              name="internal_number" 
              value={branch.internal_number ?? ''} 
              onChange={handleChange}
            />

          </div>
          <input
            type="text"
            name="cp"
            value={branch.cp}
            onChange={handleChange}
            placeholder="Código Postal*"
          />
          <input
            type="text"
            name="suburb"
            value={branch.suburb}
            onChange={handleChange}
            placeholder="Colonia/Fraccionamiento*"
          />
        </div>
      </div>
    </div>
  );
}
