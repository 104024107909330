import { ApiResponse } from "../../interfaces/response/ApiResponse";
import { AppService } from "../AppService";

export class ResourcesService{

    public static async countries():Promise<ApiResponse>{
        return (await AppService.get('/resources/get-countries')).data;
    }

    public static async states(obj:number):Promise<ApiResponse>{
        return (await AppService.get('/resources/get-states?country_id=' + obj)).data;
    }

    public static async cities(obj:string):Promise<ApiResponse>{
        return (await AppService.get('/resources/get-cities?state_code=' + obj)).data;
    }

}