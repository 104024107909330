interface RequieredLabelProps {
    text: string;
  }

export function RequieredLabel({ text }: RequieredLabelProps) {

    return (
        <div className="text-red-600 ml-4">
                {text}
        </div>
    );
}

