import "./ListOptions.css"
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../../../../store/context/AuthContext";

export function ListOptions() {
    const { dispatchUser } : any = useContext(AuthContext);
    const navigate = useNavigate();

    const handleLogout = (e: React.MouseEvent) => {
        sessionStorage.removeItem('user');
        dispatchUser({ type: 'logout', payload: {} });
        navigate('/auth');
    };

    const navigateToProfile = () => {
        navigate('/dashboard/profile');
    };

    const navigateToSubscription = () => {
        navigate('/dashboard/subscriptions');
    };

    return (
        <ul className="list-options">
            <li>
                <button onClick={() => navigateToProfile()}>
                    Mi perfil
                </button>
            </li>
            <li>
                <button onClick={() => navigateToSubscription()}>
                    Suscripciones
                </button>
            </li>
            <li>
                <button className="logout" onClick={handleLogout}>
                    Cerrar sesión
                </button>
            </li>
        </ul>
    );
}
