import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Welcome.css';
import backgroundImage from '../../../assets/landing/background.svg';

const Welcome: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate('/auth');
  };

  return (
    <div className="welcome-container" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="header-landing">
        <button className="login-button" onClick={handleNavigation}>Iniciar sesión</button>
      </div>
      <div className="content">
        <h1 className="logo-landing">mainu .</h1>
        <button className="menu-button" onClick={handleNavigation}>Quiero mi menú</button>
      </div>
    </div>
  );
};

export default Welcome;
