import React from "react";
import './WhyUs.css'
import whyusphoto from '../../../assets/landing/whyusphoto.svg'

const WhyUs: React.FC = () => {
    return (
        <div className="why-us-container">
            <div className="why-us-content-wrapper">
                <div className="why-us-image">
                    <img src={whyusphoto} alt="Why Us" />
                </div>
                <div className="why-us-content">
                    <h2>¿Porque nosotros?</h2>
                    <p>Porque buscamos facilitar el trabajo, posicionarte al mismo nivel de experiencia que solo una cadena comercial puede proporcionar.</p>
                    <p>Buscamos que el mundo te conozca.</p>
                </div>
            </div>
        </div>
    );
};

export default WhyUs;