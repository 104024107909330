import { useState } from 'react';
import './StandardCard.css';
import { TiStarFullOutline } from "react-icons/ti";
import { useNavigate } from 'react-router-dom';



interface PaymentFormProps {
    handleSelectPlan?: (plan: number) => void;
    isVisible: boolean;
    classname?: string;
    isLanding: boolean;
}

export function StandardCard({handleSelectPlan, isVisible, classname, isLanding }: PaymentFormProps) {
    const [isLandingCase, setisLandingCase] = useState<boolean>(isLanding);

    const navigate = useNavigate();

    const handleSelect = () => {
        if (isLandingCase) {
            navigate('/auth')
        }else if (handleSelectPlan) {
            handleSelectPlan(1);
        }
    };

    return (
        <div className={`standard-card ${classname}`}>
            <div className='standard-card-content'>
                <h3 className='title-card'>Estándar</h3>
                <div className='price'>
                    <p className='price-icon'>$</p>
                    <h3 className='mount'>99</h3>
                    <p className='per-mount'>/mes</p>
                </div>
                <div className='benefits'>
                    <div className='benefit-item'>
                        <TiStarFullOutline className='star-icon' />
                        <p>Edita libremente.</p>
                    </div>
                    <div className='benefit-item'>
                        <TiStarFullOutline className='star-icon' />
                        <p>Fácil gestión de tu inventario.</p>
                    </div>
                    <div className='benefit-item'>
                        <TiStarFullOutline className='star-icon' />
                        <p>Menú/catálogo dinámico.</p>
                    </div>
                    <div className='benefit-item'>
                        <TiStarFullOutline className='star-icon' />
                        <p>Generación automática de códigos QR.</p>
                    </div>
                    <div className='benefit-item'>
                        <TiStarFullOutline className='star-icon' />
                        <p>Aparición en los motores de búsqueda.</p>
                    </div>
                </div>
                {!isVisible && <button className="submit-btn" onClick={handleSelect}>Suscribirme</button>}
                
            </div>
            
        </div>
    );
}
