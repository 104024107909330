import './App.css';
import { useReducer} from 'react';
import { AppRouter } from './app/router/AppRouter';
import { AuthContext } from './app/views/store/context/AuthContext';
import { authReducer } from './app/views/store/reducers/authReducer';
import { PulseLoader } from 'react-spinners';
import { LoaderProvider, useLoader } from './app/views/store/context/LoaderContext';
import { useGlobalAxiosInterceptors } from './app/services/AppService';

const init = () => {
  let sessionUser: any = sessionStorage.getItem('user');
  let user: any;

  if (!sessionUser) {
    user = sessionUser;
  } else {
    user = JSON.parse(sessionUser);
  }

  return user;
}


const AppContent: React.FC = () => {
  const { loading } = useLoader();
  useGlobalAxiosInterceptors();

  const [user, dispatchUser] = useReducer(authReducer, {}, init);

  return (
    <div>
      {loading && (
        <div className='loader-container'>
          <PulseLoader color="#000" />
        </div>
      )}
      <AuthContext.Provider value={{ user, dispatchUser }}>
        <AppRouter />
      </AuthContext.Provider>
    </div>
  );
}

function App() {
  return (
    <LoaderProvider>
      <AppContent />
    </LoaderProvider>
  );
}

export default App;
