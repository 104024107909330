import { Routes, Route, Navigate } from "react-router-dom";
import { CategoriesSection } from "./Categories/CategoriesSection";
import { PromotionsSection } from "./Promotions/PromotionsSection";
import { ProductsSection } from "./Products/ProductsSection";
export function MenuRouter() {
    return (
            <Routes>
                <Route path="/categories" element={<CategoriesSection/>} />
                <Route path="/promotions" element={<PromotionsSection/>} />
                <Route path="/products" element={<ProductsSection/>} />
            </Routes>
    );
}
