import { ApiResponse } from "../../interfaces/response/ApiResponse";
import { Profile } from "../../interfaces/user/Profile";
import { AppService } from "../AppService";

export class ProfileService{

    //POST
    /** Profile */

    public static async updateProfile(obj:Profile):Promise<ApiResponse>{
        return (await AppService.post('/menu/put-profile', obj)).data;
    }

    public static async addProfileImage(formData: FormData):Promise<ApiResponse>{
        return (await AppService.post('/menu/profile-image', formData)).data;
    }


    //GET

    public static async getProfileData():Promise<ApiResponse>{
        return (await AppService.get('/menu/get-profile')).data;
    }


}