import { Routes, Route } from "react-router-dom";
import { VentureSection } from "./Section/General/VentureSection";
import { CreateOrEditBranch } from "./Section/CreateOrEditBranch/CreateOrEditBranch";

export function VentureRouter() {
    return (
            <Routes>
                <Route path="*" element={<VentureSection/>} />
                <Route path="/new-branch" element={<CreateOrEditBranch/>} />
                <Route path="/edit-branch/:branchId" element={<CreateOrEditBranch/>} />
            </Routes>
    );
}
