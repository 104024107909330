import { useContext, useEffect, useState } from 'react';
import { TbHandClick } from "react-icons/tb";
import { FaStore } from "react-icons/fa6";
import avatar from "../../../../assets/img/avatar.jpeg"
import { MdModeEdit } from 'react-icons/md';
import { ProfileService } from '../../../../services/Profile/ProfileService';
import { AuthContext } from '../../../store/context/AuthContext';
import Swal from 'sweetalert2';
import './ProfileSection.css';

interface Category {
    name: string;
    image: string;
    clicks: number;
}

export function ProfileSection(){
    const [profileData, setProfileData] = useState({name: "",email: "", image: ""}) 
    const [categoriesData, setCategoriesData] = useState<Category[]>([]);
    const [ventureData, setVentureData] = useState({id: "", name: "", branches_count: 0});
    const [subscriptionData, setSubscriptionData] = useState({name: "", expired: ""})
    const [image, setImage] = useState<string>(avatar);
    const { dispatchUser } : any = useContext(AuthContext);

    useEffect(() => {
        const fetchInformation = async () => {
            const resp = await ProfileService.getProfileData();
            
            if(resp.success){
                setProfileData({ ...resp.data.user });
                setCategoriesData([...resp.data.categories]);
                setVentureData({ ...resp.data.venture })
                setSubscriptionData({ ...resp.data.subscription})
                setImage(resp.data.user.image ?? avatar)
            }
        };
        fetchInformation();
    }, []);

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            // Crear un objeto FormData
            const formData = new FormData();
            formData.append('image', file);  
            // Enviar la imagen usando FormData
            const resp = await ProfileService.addProfileImage(formData);
            if (resp.success) {
                Swal.fire({
                    title: resp.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 3000
                });
                const updatedImageUrl = URL.createObjectURL(file);
                
                setImage(updatedImageUrl); 
                dispatchUser({
                    type: 'update_profile_image',
                    payload: updatedImageUrl
                });

            } else {
                Swal.fire({
                    title: 'Error al subir la imagen',
                    icon: 'error',
                    showConfirmButton: true,
                });
            }
            
        }
            
    };

    return (
        <div className='profile-section'>
            <h1>Mi perfil</h1>
            <div className="profile-dashboard"> 
                <div className='column-1'>
                    <div className='profile-card card'>
                        {/*<button className='edit-btn'><MdModeEdit /></button>*/}
                        <div className='image-profile'>
                            <img src={image} alt="" />
                            <button className="upload-btn">
                                <label htmlFor="image-upload" style={{ cursor: 'pointer' }}>Subir imagen</label>
                                <input
                                    type="file"
                                    id="image-upload"
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange} 
                                    accept="image/*"
                                />
                            </button>
                        </div>
                        <div className='profile-text'>
                            <h3>{profileData.name}</h3>
                            <h3>{profileData.email}</h3>
                            {/*<h3>6484653792</h3>*/}
                        </div>
                    </div>
                    <div className='subscription-card card'>
                        <h3>Suscripción</h3>
                        <div className='info-subs'>
                            <h4>{subscriptionData.name}</h4>
                            <p>Vencimiento: {subscriptionData.expired}</p>
                        </div>
                    </div>
                </div>
                <div className='column-2'>
                    <div className="categories-card card">
                        <h3>Categorías más consultadas</h3>
                        <div className="category-list">
                        {categoriesData.map((category, index) => (
                            <div 
                                key={index}
                                className="category-item" 
                                style={{ backgroundImage: `url(${category.image})` }}
                            >
                                <p>{index + 1}. {category.name}</p>
                                <div className='category-clicks'>
                                    <span>{category.clicks}</span>
                                    <TbHandClick className='click-icon'/>
                                </div>
                            </div>
                        ))}
                        </div>
                    </div>
                    <div className="store-card card">
                        <div className='store-head'>
                            <h3>{ventureData.name}</h3>
                            <FaStore className='store-icon'/>
                        </div>
                        <div className='store-action'>
                            <p>{ventureData.branches_count} sucursales</p>
                            <button className='submit-btn'>Generar nuevo QR</button>
                        </div>

                    </div>
                </div>


            </div>
        </div>
    );
}
