import { CompanyCard } from "./Components/CompanyCard/CompanyCard";
import { DeviceFrameset } from 'react-device-frameset';
import { useNavigate } from "react-router-dom";
import 'react-device-frameset/styles/marvel-devices.min.css';
import logo from "../../../assets/img/logo.png";
import { NewUserTour } from "./NewUser/NewUserTour";
import './Home.css';
import { useEffect, useState } from "react";

interface HomeProps {
    isNewUser: boolean;
}

export function Home({isNewUser} : HomeProps){
    const hasProducts = false;
    const navigate = useNavigate();
    const [step, setStep] = useState<number>(1);
    const [openTour, setOpenTour] = useState<boolean>(false);
    

    useEffect(() => {
        setOpenTour(isNewUser);
    }, [isNewUser]);

    const navigateToCategories = () => {
        navigate('/dashboard/menu/categories');
    };

    const navigateToPromotions = () => {
        navigate('/dashboard/menu/promotions');
    };

    const navigateToProducts = () => {
        navigate('/dashboard/menu/products');
    };

    const navigateToMenu = () => {
        navigate('/dashboard/design-menu');
    };

    const handleNextStep = () => {
        setStep(step + 1);
        let count = step + 1;
        if(count > 5 ){
            setOpenTour(false);
            window.history.replaceState(null, "", "/dashboard/home");
        }
    };
    
    
    return (
     <div className="main-menu">
        {openTour && <NewUserTour handleNextStep={handleNextStep} step={step} />}
        <CompanyCard isSelectedByTour={step == 1}/>
        <div className="settings__menu">
            <div className="device__view">
                <DeviceFrameset device="iPhone X" color="black">
                    {
                        hasProducts ? 
                        <div className="empty__display"> 
                            <img src={logo} alt="" />
                        </div> :
                        <iframe
                            src="http://localhost:3000/auth"
                            width="100%"
                            height="100%"
                            style={{ border: 'none' }}
                            title="Example Iframe"
                        ></iframe>
                    }
                </DeviceFrameset>
            </div>
            <div className="settings_options">
                <div className="qr card">
                    <img src="https://t3.gstatic.com/licensed-image?q=tbn:ANd9GcSh-wrQu254qFaRcoYktJ5QmUhmuUedlbeMaQeaozAVD4lh4ICsGdBNubZ8UlMvWjKC" alt="" />
                    <button className="btn-qr">Descargar QR</button>
                </div>
                <button className={`settings card ${step == 2 ? 'selected-tour' : null}`} onClick={() => navigateToCategories()}>
                    Categorias
                </button>
                <button className={`settings card ${step == 3 ? 'selected-tour' : null}`} onClick={() => navigateToProducts()}>
                    Productos
                </button>
                <button className={`settings card ${step == 4 ? 'selected-tour' : null}`} onClick={() => navigateToPromotions()}>
                    Banners
                </button>
                <button className={`settings card ${step == 5 ? 'selected-tour' : null}`} onClick={() => navigateToMenu()}>
                    Editar mi menu
                </button>
                <button className="settings card view__screen">
                    Visualizar mi menu
                </button>
            </div>
        </div>
     </div>   
    );
}