import { useState } from 'react';
import { ListOptions } from '../ListOptions/ListOptions';
import './MenuMobile.css';

export function MenuMobile() {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div className="menu-mobile">
            <button className={`btn-menu-burguer ${menuOpen ? 'active' : ''}`} onClick={toggleMenu}>
                <div></div>
                <div></div>
                <div></div>
            </button>
            <div className={`side-menu ${menuOpen ? 'open' : ''}`}>
                <div className='side-menu-content'>

                </div>
                <nav className='options'>
                    <ListOptions />
                </nav>
            </div>
        </div>
    );
}
