import { Navigate} from 'react-router-dom';

interface Props {
    loggedIn: boolean | undefined;
    children: JSX.Element;
}

export function PrivateRouter({ loggedIn, children }: Props) {
    return loggedIn ? children : <Navigate to="/auth" />;
}
