import React from "react";
import './Footer.css';
import instagramIcon from '../../../assets/landing/Instagram.svg';
import facebookIcon from '../../../assets/landing/Facebook.svg';

const Footer: React.FC = () => {
    return (
        <footer className="footer-container">
            <div className="footer-content-wrapper">
                <div className="footer-logo">
                    <h2>mainu<span className="dot">.</span></h2>
                </div>
                <div className="footer-right">
                    <div className="footer-socials">
                        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                            <img src={instagramIcon} alt="Instagram" />
                        </a>
                        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                            <img src={facebookIcon} alt="Facebook" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="footer-info">
                <p>© All Rights Reserved</p>
                <p>Chihuahua, Mexico</p>
                <p><a href="mailto:mainu.help@gmail.com">mainu.help@gmail.com</a></p>
            </div>
        </footer>
    );
};

export default Footer;