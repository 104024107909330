import './ToggleSwitch.css';

interface ToggleSwitchProps {
    checked: boolean;
    onChange: () => void;
    label?: string;
}

export function ToggleSwitch({ checked, onChange, label }: ToggleSwitchProps) {
    return (
        <div className="toggle-switch-container">
            {label && <label className="toggle-switch-label">{label}</label>}
            <label className="switch">
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={onChange}
                />
                <span className="slider"></span>
            </label>
        </div>
    );
}
