import './CategoryFormModal.css';
import { Category } from '../../../../../interfaces/Menu/Category';
import { IoClose } from 'react-icons/io5';
import { useState } from 'react';
import { MenuService } from '../../../../../services/Menu/MenuService';
import { ToggleSwitch } from '../../Components/ToggleSwitch';
import Swal from 'sweetalert2';

interface CategoryFormModalProps {
    closeModal: () => void;
    category: Category;
    isNew: boolean;
    updateCategories: (updatedCategory: Category) => void;
}

export function CategoryFormModal({ closeModal, category, isNew,  updateCategories }: CategoryFormModalProps) {
    const [isPublished, setIsPublished] = useState<boolean>(category.status);
    const [categoryId, setCategoryId] = useState(!isNew ? category.id : "");
    const [image, setImage] = useState<string>(category.image ?? "");
    const [categoryForm, setCategoryForm] = useState<Category>({
        id: category.id,
        name: category.name,
        description: category.description,
        status: category.status
    });

    const handleSaveCategory = async (e: React.MouseEvent) => {
        e.preventDefault();
        if(isNew && categoryId == ""){
            const resp = await MenuService.addCategory(categoryForm);
            if (resp.success) {
                Swal.fire({
                    title: resp.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 3000
                });
                updateCategories(resp.data); 
                setCategoryForm({ ...categoryForm, id: resp.data.id });
                setCategoryId(resp.data.id);
            }
        }else{
            const resp = await MenuService.updateCategory(categoryForm);
            if (resp.success) {
                Swal.fire({
                    title: resp.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 3000
                });
                updateCategories(resp.data); 
                setCategoryForm({ ...categoryForm, id: resp.data.id });
                setCategoryId(resp.data.id);

            }
        }

    };

    const handleTogglePublished = () => {
        setCategoryForm({ ...categoryForm, status: !isPublished });
        setIsPublished(!isPublished);
        
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setCategoryForm({ ...categoryForm, [e.target.name]: e.target.value });
    };

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            if(categoryId){
                // Crear un objeto FormData
                const formData = new FormData();
                formData.append('image', file);  // 'image' debe coincidir con lo que espera tu backend
                formData.append('category_id', categoryId);
                // Enviar la imagen usando FormData
                const resp = await MenuService.addCategoryImage(formData);
                if (resp.success) {
                    Swal.fire({
                        title: resp.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 3000
                    });
                    setImage(URL.createObjectURL(file)); // Actualizar la imagen mostrada
                } else {
                    Swal.fire({
                        title: 'Error al subir la imagen',
                        icon: 'error',
                        showConfirmButton: true,
                    });
                }
            }
        }
            
    };

    return (
        <div className="category-form-modal">
            <div className="category-form-modal-content">
                <button className="close-btn" onClick={closeModal}><IoClose /></button>
                <h3>{isNew ? "Agregar Categoría" : "Editar Categoría"}</h3>
                <form>
                    <label htmlFor="category-name">Nombre</label>
                    <input
                        id="category-name"
                        type="text"
                        value={categoryForm.name}
                        placeholder="Nombre de la categoría"
                        name='name'
                        required
                        onChange={handleChange}
                    />
                    <label htmlFor="category-description">Descripción</label>
                    <textarea
                        id="category-description"
                        rows={4}
                        value={categoryForm.description}
                        name='description'
                        placeholder="Descripción de la categoría"
                        onChange={handleChange}
                        maxLength={220}
                    />
                    <ToggleSwitch
                        label="Activo"
                        checked={isPublished}
                        onChange={handleTogglePublished}
                    />
                </form>
                {categoryId &&
                    <div className="image-category">
                        {image ? <img src={image} alt="Category" /> : <div className='empty-image'>Agrega una imagen...</div>}
                        <button className="upload-btn">
                            <label htmlFor="image-upload" style={{ cursor: 'pointer' }}>Subir imagen</label>
                            <input
                                type="file"
                                id="image-upload"
                                style={{ display: 'none' }}
                                onChange={handleFileChange} 
                                accept="image/*"
                            />
                        </button>
                    </div>
                }
                
                
                <button className='submit-btn' type="submit" onClick={(e) => handleSaveCategory(e)}>Guardar</button>
            </div>
        </div>
    );
}
