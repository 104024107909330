import React, { useContext } from 'react';
import './PhotoName.css';
import avatar from "../../../../../assets/img/avatar.jpeg";
import { AuthContext } from '../../../../store/context/AuthContext';

export function PhotoName() {
  const { user }: any = useContext(AuthContext);  // Obtener el contexto del usuario
  
  return (
    <div className='profile'>
      {user?.name || 'Usuario'}
      
      <div className='profile-img'>
        <img src={user?.image || avatar} alt={user?.name || 'Usuario'}  />
      </div>
      
    </div>
  );
}