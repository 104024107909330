// src/services/AppService.ts
import axios from 'axios';
import { env } from '../../env';
import { useLoader } from '../views/store/context/LoaderContext';

const axiosInstance = axios.create({
    baseURL: env.baseUrl,
});

axiosInstance.interceptors.request.use(
    (config) => {
        const sessionUser = sessionStorage.getItem('user');
        if (sessionUser) {
            const user = JSON.parse(sessionUser);
            const token = user.access;
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        // Si la respuesta es exitosa, simplemente la retornamos
        return response;
    },
    (error) => {
        // Manejo de errores
        const { response } = error;
        if (response && response.status === 401) {
            // Limpiar el sessionStorage en caso de token expirado
            sessionStorage.removeItem('user');
            // Redirigir al usuario a la página de inicio de sesión o mostrar un mensaje de error
            // Aquí podrías redirigir al usuario usando React Router o una librería similar
            window.location.href = '/login'; // O la ruta de inicio de sesión que uses
        }
        return Promise.reject(error);
    }
);

export class AppService {
    static baseUrl = env.baseUrl;

    public static post(path: string, obj: any): Promise<any> {
        return axiosInstance.post(path, obj);
    }

    public static get(path: string): Promise<any> {
        return axiosInstance.get(path);
    }

    public static put(path: string, obj: any): Promise<any> {
        return axiosInstance.put(path, obj);
    }

    public static delete(path: string): Promise<any> {
        return axiosInstance.delete(path);
    }
}

// Configurar interceptores
export const useGlobalAxiosInterceptors = () => {
    const { setLoading } = useLoader();

    axiosInstance.interceptors.request.use(
        (config) => {
            setLoading(true);
            return config;
        },
        (error) => {
            setLoading(false);
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (response) => {
            setLoading(false);
            return response;
        },
        (error) => {
            setLoading(false);
            return Promise.reject(error);
        }
    );

    // Interceptor para configurar automáticamente 'Content-Type' como 'multipart/form-data' si el 'data' es una instancia de 'FormData'
    axiosInstance.interceptors.request.use(
        (config) => {
            if (config.data instanceof FormData) {
                config.headers['Content-Type'] = 'multipart/form-data';
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
};
