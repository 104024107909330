import './CompanyCard.css';
import noImage from '../../../../../assets/img/no-image.jpg';
import { useNavigate } from 'react-router-dom';

interface CompanyCardProps{
    isSelectedByTour: boolean;
}

export function CompanyCard({ isSelectedByTour }: CompanyCardProps){

    const navigate = useNavigate();
    
    const navigateToVenture = () => {
        navigate('/dashboard/venture');
    };

    return (
     <div className={`company-card card ${isSelectedByTour ? 'selected-tour' : null}`}>
        <div className='company-content'>
            <img src={noImage} alt="company-logo" className='company-logo'/>
            <div className='company-info-card'>
                <h2>La cafeteria del Taro</h2>
                <p>Calle 16 sur, 17582, campos minados, 33012, Chihuahua, Chihuahua, Mexico</p>
            </div>
        </div>
        <button className='text-edit-info' onClick={navigateToVenture}>
            Editar información
        </button>
     </div>   
    );
}