import { useEffect, useState } from 'react';
import './ProductsSection.css';
import noImage from '../../../../assets/img/no-image.jpg';
import { ProductFormModal } from './ProductFormModal/ProductFormModal';
import { Product } from '../../../../interfaces/Menu/Product';
import { MenuService } from '../../../../services/Menu/MenuService';
import { MdModeEdit, MdDelete } from "react-icons/md";
import Swal from 'sweetalert2';

export function ProductsSection() {
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [isNew, setIsNew] = useState<boolean>(true);
    const [products, setProducts] = useState<Product[]>([]);
    const [allProducts, setAllProducts] = useState<Product[]>([]); // Nueva variable de estado
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
    const [categories, setCategories] = useState<{ id: number, name: string, status:boolean }[]>([]);
    const [sortOrder, setSortOrder] = useState<string>('');
    const [productOpen, setProductOpen] = useState<Product>({
        id: "",
        category_id: null,
        name: "",
        description: "",
        is_multiprice: false,
        price: 0.00,
        prices: [],
        image: "",
        status: false,
    });

    //paginacion
    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerPage] = useState(10);

    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);
    const totalPages = Math.ceil(products.length / productsPerPage);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };
    


    useEffect(() => {
        const fetchInformation = async () => {
            const response = await MenuService.getProducts();
        
            const products = response.data.map((product: Product) => ({
                id: product.id,
                category_id: product.category_id,
                name: product.name,
                description: product.description,
                is_multiprice: product.is_multiprice,
                price: product.price,
                prices: product.prices,
                image: product.image,
                status: product.status
            }));
            setProducts(products);
            setAllProducts(products); // También almacenamos todos los productos
        };
        fetchInformation();
    }, []);

    useEffect(() => {
        const fetchCategories = async () => {
            const resp = await MenuService.getCategories();
            if (resp.success) {
                setCategories(resp.data);
            }
        };
        fetchCategories();
    }, []);

    const updateProducts = (updatedProduct: Product) => {
        setProducts(prevProducts => {
            const productExists = prevProducts.some(product => product.id === updatedProduct.id);
            if (productExists) {
                return prevProducts.map(product =>
                    product.id === updatedProduct.id ? updatedProduct : product
                );
            } else {
                return [...prevProducts, updatedProduct];
            }
        });

        setAllProducts(prevAllProducts => {
            const productExists = prevAllProducts.some(product => product.id === updatedProduct.id);
            if (productExists) {
                return prevAllProducts.map(product =>
                    product.id === updatedProduct.id ? updatedProduct : product
                );
            } else {
                return [...prevAllProducts, updatedProduct];
            }
        });
    };

    const closeModal = () => {
        setIsFormModalOpen(false);
    };

    const handleDeleteProduct = (id: string | undefined) => {
        if (!id) {
            console.error("ID de producto no proporcionado");
            return;
        }

        Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#000',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const resp = await MenuService.deleteProduct(id);
                    if (resp.success) {
                        const newProducts = products.filter(product => product.id !== id);
                        setProducts(newProducts);
                        setAllProducts(newProducts);

                        Swal.fire({
                            title: '¡Eliminado!',
                            text: resp.message,
                            icon: 'success',
                            confirmButtonColor: '#000',
                        });
                    }
                } catch (error) {
                    console.error('Error eliminando el producto:', error);
                    Swal.fire({
                        title: 'Error',
                        text: 'Hubo un problema al eliminar el producto.',
                        icon: 'error',
                        confirmButtonColor: '#000',
                    });
                }
            }
        });
    };

    const handleOpenForm = (e: React.FormEvent, isNew: boolean, id: string | undefined) => {
        e.preventDefault();
        setIsNew(isNew);
        setIsFormModalOpen(true);
        if (id) {
            const product = products.find(product => product.id === id);
            if (product) {
                setProductOpen(product);
            } else {
                setProductOpen({
                    id: "",
                    category_id: null,
                    name: "",
                    description: "",
                    is_multiprice: false,
                    price: 0,
                    prices: [],
                    image: "",
                    status: false
                });
            }
        } else {
            setProductOpen({
                id: "",
                category_id: null,
                name: "",
                description: "",
                is_multiprice: false,
                price: 0,
                prices: [],
                image: "",
                status: false
            });
        }
    };

    const truncateText = (text: string, maxLength: number): string => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const getCategoryName = (id: number): string => {
        const category = categories.find(cat => cat.id === id);
        return category ? category.name : "Categoría desconocida";
    };

    const isActive = (category_id: number, status:boolean): boolean => {
        const category = categories.find(cat => cat.id === category_id)

        return category?.status == true && status
    };

    const handleFilterCategory = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value || null;
        setSelectedCategory(selectedValue);
        
        let filteredProducts = selectedValue
            ? allProducts.filter(prod => prod.category_id === Number(selectedValue))
            : allProducts;
        
        setProducts(filteredProducts);
        
        // Aplica el ordenamiento si se ha seleccionado un criterio
        if (sortOrder) {
            sortProducts(sortOrder, filteredProducts);
        }
    };
    
    const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOrder = event.target.value;
        setSortOrder(selectedOrder);
        
        // Aplica el ordenamiento a los productos actuales
        sortProducts(selectedOrder, products);
    };
    
    const sortProducts = (criteria: string, productsToSort: Product[]) => {
        let sortedProducts = [...productsToSort];
        
        switch (criteria) {
            case 'name-asc':
                sortedProducts.sort((a, b) => a.name.localeCompare(b.name));
                break;
            case 'name-desc':
                sortedProducts.sort((a, b) => b.name.localeCompare(a.name));
                break;
            case 'price-asc':
                sortedProducts.sort((a, b) => a.price - b.price);
                break;
            case 'price-desc':
                sortedProducts.sort((a, b) => b.price - a.price);
                break;
            default:
                return;
        }
        
        setProducts(sortedProducts);
    };

    return (
        <div className="products">
            <h2>Productos</h2>
            <div className='btn-add-product'>
                <button className='submit-btn' onClick={(e) => handleOpenForm(e, true, "")}>Agregar +</button>
            </div>
            
            <div className='filters'>
                <select onChange={handleFilterCategory} className='category-filter'>
                    <option value="">Categoría</option>
                    {categories.map(category => (
                        <option key={category.id} value={category.id}>{category.name}</option>
                    ))}
                </select>
                <div className='order-by'>
                    <label htmlFor="order">Ordenar por: </label>
                    <select className='order' onChange={handleSortChange}>
                        <option value="name-asc">Nombre (a-z)</option>
                        <option value="name-desc">Nombre (z-a)</option>
                        <option value="price-asc">Precio (Menor - Mayor)</option>
                        <option value="price-desc">Precio (Mayor - Menor)</option>
                    </select>
                </div>
            </div>


            <div className='products-table-header card'>
                <div className='product-column'>Producto</div>
                <div className='category-column'>Categoría</div>
                <div className='price-column'>Precio</div>
                <div className='status-column'>Status</div>
                <div className='action-column'>Acciones</div>
            </div>
            <div className='products-cards'>
                {currentProducts.length > 0
                    ? currentProducts.map((product) => (
                        <div key={product.id} className='products-table card'>
                            <div className='product-column'>
                                <div className='product-image'>
                                    <img src={product.image !== "" ? product.image : noImage} alt="product-image" />
                                </div>
                                
                                <div className='product-text'>
                                    <h3>{truncateText(product.name, 20)}</h3>
                                </div>
                            </div>
                            <div className='category-column'>{getCategoryName(Number(product.category_id))}</div>
                            <div className='price-column'>${product.price}</div>
                            <div className='status-column'>
                                <span className={isActive(Number(product.category_id), product.status)  ? "active" : "inactive"}>
                                    {isActive(Number(product.category_id), product.status) ? "Activo" : "Inactivo"}
                                </span>
                            </div>
                            <div className='action-column'>
                                <div className="btn-group">
                                    <button onClick={(e) => handleOpenForm(e, false, product.id)}>
                                        <MdModeEdit className="edit" />
                                    </button>
                                    <button onClick={(e) => handleDeleteProduct(product.id)}>
                                        <MdDelete className="delete"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))
                    : <div className='empty-products'>No cuentas con productos agregados :(</div>
                }
            </div>
            <div className="pagination">
                <button 
                    onClick={() => handlePageChange(currentPage - 1)} 
                    disabled={currentPage === 1}
                    className='submit-btn'
                >
                    Anterior
                </button>
                <span>Página {currentPage} de {totalPages}</span>
                <button 
                    onClick={() => handlePageChange(currentPage + 1)} 
                    disabled={currentPage === totalPages}
                    className='submit-btn'
                >
                    Siguiente
                </button>
            </div>

            {isFormModalOpen && (
                <ProductFormModal
                    closeModal={closeModal}
                    product={productOpen}
                    isNew={isNew}
                    updateProducts={updateProducts}
                    categories={categories}
                />
            )}
        </div>
    );
}
