import { useEffect, useState } from 'react';
import './PromotionsSection.css';
import noImage from '../../../../assets/img/no-image.jpg';
import { PromotionFormModal } from './PromotionFormModal/PromotionFormModal';
import { Promotion } from '../../../../interfaces/Menu/Promotion';
import { MenuService } from '../../../../services/Menu/MenuService';
import Swal from 'sweetalert2';

export function PromotionsSection(){
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [isNew, setIsNew] = useState<boolean>(true);
    const [promotions, setPromotions] = useState<Promotion[]>([]);
    const [promotionOpen, setPromotionOpen] = useState<Promotion>({
        id:"",
        name: "",
        description: "",
        image: "",
        status: false
    });

    useEffect(() => {
        const fetchInformation = async () => {
            const response = await MenuService.getPromotions();
            const promotions = response.data.map((promotion: Promotion) => ({
                id: promotion.id,
                name: promotion.name,
                description: promotion.description,
                image: promotion.image,
                status: promotion.status
            }));
            setPromotions(promotions);
        };
        fetchInformation();
    }, []);

    // Función para actualizar o agregar una nueva promoción
    const updatePromotions = (updatedPromotion: Promotion) => {
        setPromotions(prevPromotions => {
            const promotionExists = prevPromotions.some(promotion => promotion.id === updatedPromotion.id);
            if (promotionExists) {
                // Si la promoción ya existe, la actualizamos
                return prevPromotions.map(promotion =>
                    promotion.id === updatedPromotion.id ? updatedPromotion : promotion
                );
            } else {
                // Si la promoción no existe, la agregamos al listado
                return [...prevPromotions, updatedPromotion];
            }
        });
    };
    
    const closeModal = () => {
        setIsFormModalOpen(false);
    };

    const handleDeletePromotion = (id: string | undefined) => {

        if (!id) {
            console.error("ID de promoción no proporcionado");
            return;
        }
        
        Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#000',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
          }).then(async (result) => {
            if (result.isConfirmed) {
              try {
                // Llama al servicio para eliminar la promoción
                const resp = await MenuService.deletePromotion(id);
                if(resp.success){
                  const newPromotions = promotions.filter(promotion => promotion.id !== id);
                  setPromotions(newPromotions);
          
                  // Muestra un mensaje de éxito
                  Swal.fire({
                        title:'¡Eliminado!',
                        text: resp.message,
                        icon:'success',
                        confirmButtonColor: '#000',
                    }
                  );
                }
              } catch (error) {
                console.error('Error eliminando la promoción:', error);
                Swal.fire({
                  title:'Error',
                  text:'Hubo un problema al eliminar la promoción.',
                  icon:'error',
                  confirmButtonColor: '#000',
                });
              }
            }
          });
        };
    
    const handleOpenForm = (e: React.FormEvent, isNew: boolean, id: string | undefined) => {
        e.preventDefault();
        setIsNew(isNew);
        setIsFormModalOpen(true);
        if(id){
            const promotion = promotions.find(promotion => promotion.id === id);
            if (promotion) {
                setPromotionOpen(promotion);  // Si se encuentra, establecemos la promoción abierta.
            } else {
                setPromotionOpen({
                    id: "",
                    name: "",
                    description: "",
                    image: "",
                    status: false
                });
            }
        }else{
            setPromotionOpen({
                id: "",
                name: "",
                description: "",
                image: "",
                status: false
            });
        }
      };

    const truncateText = (text: string, maxLength: number): string => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };
    
    return (
        <div className="promotions">
            <h2>Promociones</h2>
            <div className='btn-add-promotion'>
                <button className='submit-btn' onClick={(e) => handleOpenForm(e, true, "")}>Agregar +</button>
            </div>

            <div className='promotions-cards'>
                {promotions.length > 0
                    ?  
                    promotions.map((promotion) => (
                    <div key={promotion.id} className='promotion-card card'>
                        <img src={promotion.image != "" ? promotion.image : noImage } alt="promotion-image" />
                        <div className='promotion-info'>
                            <div className='promotion-text'>
                            <h3>{promotion.name}</h3>
                            <p>{truncateText(promotion.description, 400)}</p>
                            </div>
                        </div>
                        <div className='btn-action-group'>
                            <button className='edit-promotion' onClick={(e) => handleOpenForm(e, false, promotion.id)}>Editar</button>
                            <button className='delete-promotion' onClick={(e) => handleDeletePromotion(promotion.id)}>Eliminar</button>
                        </div>
                    </div>
                    ))
                    :
                    <div>No cuentas con promociones agregadas:(</div>
                }
            </div>

            {isFormModalOpen && (
                <PromotionFormModal 
                closeModal={closeModal} 
                promotion={promotionOpen} 
                isNew={isNew}
                updatePromotions={updatePromotions}
                />
            )}

        </div>
    );
}
